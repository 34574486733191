type Options = {
  isImmediate: boolean
}

export function debounce<F extends (...args: any[]) => Promise<void>>(
  func: F,
  waitMilliseconds: number,
  options: Options = {
    isImmediate: false,
  },
): (this: ThisParameterType<F>, ...args: Parameters<F>) => Promise<void> {
  let timeoutId: ReturnType<typeof setTimeout> | null
  return async function (this: ThisParameterType<F>, ...args: Parameters<F>) {
    const context = this
    const doLater = async function () {
      timeoutId = null
      if (!options.isImmediate) {
        await func.apply(context, args)
      }
    }
    const shouldCallNow = options.isImmediate && timeoutId == null
    if (timeoutId != null) {
      clearTimeout(timeoutId)
    }
    timeoutId = setTimeout(doLater, waitMilliseconds)
    if (shouldCallNow) {
      await func.apply(context, args)
    }
  }
}
