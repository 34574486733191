import { Singleton } from '@dvolper/tsdi'
import {
  AcsSessionService,
  CloudServiceConfiguration,
  ContentType,
  HttpClient,
  HttpStatusCode,
} from '@matrix42/cloud-common'
import { MarketplaceReviews } from '@/models/marketplace-reviews'
import { Store } from 'vuex'
import { AppState } from '@/store/app-state'

@Singleton
export class ReviewService {
  public constructor(
    private readonly _client: HttpClient,
    configuration: CloudServiceConfiguration,
    private readonly _sessionService: AcsSessionService,
    private readonly _store: Store<AppState>,
  ) {
    this._client.baseUri = configuration.baseUri
  }

  public async getPackageReviews(packageId: string, skip?: number, take?: number): Promise<MarketplaceReviews> {
    let uri = '/api/gallery/packages/' + packageId + '/reviews'
    if (skip != null || take != null) {
      const params = new URLSearchParams()
      if (skip != null) params.append('skip', skip.toString())
      if (take != null) params.append('take', take.toString())
      uri += '?' + params.toString()
    }
    const response = await this._client.getAsync(uri, {
      Authorization: 'Bearer ' + this._sessionService.bearerToken,
    })
    if (!response.isSuccessStatusCode) {
      if (response.statusCode === HttpStatusCode.ServiceUnavailable) {
        this._store.commit('set_maintenance_mode', true)
      }
      if (response.statusCode === HttpStatusCode.NotFound) return null
      throw new Error('Invalid status code received when trying to get package reviews: ' + response.statusCode)
    }
    return response.content
  }

  public async createPackageReview(packageId: string, rating: number, content: string): Promise<void> {
    const response = await this._client.postAsync(
      '/api/gallery/packages/' + packageId + '/reviews',
      JSON.stringify({
        content,
        rating,
      }),
      {
        Authorization: 'Bearer ' + this._sessionService.bearerToken,
        'Content-Type': ContentType.ApplicationJson,
      },
    )
    if (!response.isSuccessStatusCode) {
      if (response.statusCode === HttpStatusCode.ServiceUnavailable) {
        this._store.commit('set_maintenance_mode', true)
      }
      throw new Error('Invalid status code received when trying to create package review: ' + response.statusCode)
    }
  }
}
