export class ApiError extends Error {
  public get type(): string {
    return this._type
  }

  public constructor(private readonly _type: string, message: string) {
    super(message)

    // is needed for TS ES5 transpiling bug (https://stackoverflow.com/a/48342359)
    Object.setPrototypeOf(this, new.target.prototype)
  }
}
