














































































import Vue from 'vue'
import { Component, Prop } from 'vue-property-decorator'
import { M42Banner, M42Button, M42Input, M42Modal } from '@matrix42/ignition-vue'
import { Getter } from 'vuex-class'
import { DataState } from '@/store/app-state'
import { InstallTarget } from '@/models/install-target'
import { ValidationObserver, ValidationProvider } from 'vee-validate'
import { InstallService } from '@/services/install-service'
import { Resolve } from '@dvolper/tsdi'
import { CustomerProfile } from '@/models/customer-profile'
import CustomerProfiles from '@/views/customer-profiles.vue'

@Component({
  components: {
    M42Banner,
    M42Button,
    M42Input,
    M42Modal,
    ValidationObserver,
    ValidationProvider,
  },
})
export default class AddDwpEnvironmentModal extends Vue {
  @Resolve
  private readonly _installService: InstallService
  public isTargetAdding: boolean = false
  public addInstallTargetURL: string = null

  @Getter('current_profile')
  public currentProfile: CustomerProfile

  @Getter('customer_profiles')
  public customerProfiles: DataState<CustomerProfile>

  @Prop({
    type: Boolean,
    default: false,
  })
  public open: boolean

  public get isNew(): boolean {
    return (
      !this.currentProfile ||
      (!this.currentProfile.installationEnvironments.length && this.customerProfiles.cache.length === 1)
    )
  }

  public async onAddTarget(): Promise<void> {
    const form = <any>this.$refs.addTargetForm
    if (!(await form.validate())) return
    this.isTargetAdding = true
    const targetProfile = window.localStorage.getItem('M42-TARGET-PROFILE-TMP')
    if (!targetProfile) {
      window.localStorage.setItem('M42-TARGET-PROFILE-TMP', this.currentProfile.id)
    }
    this._installService.authenticateTarget(this.addInstallTargetURL)
  }
}
