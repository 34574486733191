export const setHashParameter = (key: string, value: string): void => {
  //TODO make reusable
  window.location.hash = key + '=' + encodeURIComponent(value)
}

export const getHashParameter = (key: string): string => {
  const hash = window.location.hash.substr(1)
  const parameters = hash.split('&')
  for (let parameter of parameters) {
    const kvp = parameter.split('=')
    if (kvp[0] === key) return decodeURIComponent(kvp[1])
  }
  return null
}

export const removeHashParameter = (key: string): void => {
  //TODO make reusable
  window.location.hash = ''
}
