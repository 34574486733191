import { Singleton } from '@dvolper/tsdi'
import {
  AcsSessionService,
  CloudServiceConfiguration,
  ContentType,
  HttpClient,
  HttpStatusCode,
} from '@matrix42/cloud-common'
import { CustomerProfile } from '@/models/customer-profile'
import { Store } from 'vuex'
import { AppState } from '@/store/app-state'
import { InstallTarget, StorableInstallTarget } from '@/models/install-target'
import { InstalledPackage } from '@/models/installed-package'
import { DwpConfigService } from '@/services/dwp-config-service'
import { ApiError } from '@/common/api-error'

@Singleton
export class CustomerProfileService {
  private readonly _storageKey: string = 'M42-INSTALL-TARGETS'

  public constructor(
    private readonly _client: HttpClient,
    configuration: CloudServiceConfiguration,
    private readonly _sessionService: AcsSessionService,
    private readonly _store: Store<AppState>,
    private readonly _dwpConfigService: DwpConfigService,
  ) {
    this._client.baseUri = configuration.baseUri
  }

  public async recreateCustomerProfile(id: string): Promise<CustomerProfile> {
    const response = await this._client.postAsync('/api/gallery/customerProfiles/' + id + '/recreate', null, {
      Authorization: 'Bearer ' + this._sessionService.bearerToken,
    })
    if (!response.isSuccessStatusCode) {
      if (response.statusCode === HttpStatusCode.ServiceUnavailable) {
        this._store.commit('set_maintenance_mode', true)
      }
      if (response.content && response.content.error) {
        throw new ApiError(response.content.error.type, response.content.error.message)
      }
      throw new Error('Invalid status code received when trying to recreate customer profile: ' + response.statusCode)
    }
    return response.content
  }

  public async createCustomerProfile(
    name: string,
    vendorId: string,
    requestApproval: boolean,
  ): Promise<CustomerProfile> {
    const response = await this._client.postAsync(
      '/api/gallery/customerProfiles',
      {
        Name: name,
        VendorId: vendorId,
        RequestApproval: requestApproval,
      },
      {
        Authorization: 'Bearer ' + this._sessionService.bearerToken,
        'Content-Type': ContentType.ApplicationJson,
      },
    )
    if (!response.isSuccessStatusCode) {
      if (response.statusCode === HttpStatusCode.ServiceUnavailable) {
        this._store.commit('set_maintenance_mode', true)
      }
      if (response.content && response.content.error) {
        throw new ApiError(response.content.error.type, response.content.error.message)
      }
      throw new Error('Invalid status code received when trying to create customer profile: ' + response.statusCode)
    }
    return response.content
  }

  public async deleteCustomerProfile(id: string): Promise<void> {
    const response = await this._client.deleteAsync('/api/gallery/customerProfiles/' + id, null, {
      Authorization: 'Bearer ' + this._sessionService.bearerToken,
    })
    if (!response.isSuccessStatusCode) {
      if (response.statusCode === HttpStatusCode.ServiceUnavailable) {
        this._store.commit('set_maintenance_mode', true)
      }
      throw new Error('Invalid status code received when trying to delete customer profile: ' + response.statusCode)
    }
  }

  public async updateCustomerProfile(id: string, name: string): Promise<void> {
    const response = await this._client.postAsync(
      '/api/gallery/customerProfiles/' + id,
      {
        Name: name,
      },
      {
        Authorization: 'Bearer ' + this._sessionService.bearerToken,
        'Content-Type': ContentType.ApplicationJson,
      },
    )
    if (!response.isSuccessStatusCode) {
      if (response.statusCode === HttpStatusCode.ServiceUnavailable) {
        this._store.commit('set_maintenance_mode', true)
      }
      throw new Error('Invalid status code received when trying to update customer profile: ' + response.statusCode)
    }
  }

  public async getCustomerProfiles(): Promise<CustomerProfile[]> {
    const response = await this._client.getAsync('/api/gallery/customerProfiles', {
      Authorization: 'Bearer ' + this._sessionService.bearerToken,
    })
    if (!response.isSuccessStatusCode) {
      if (response.statusCode === HttpStatusCode.ServiceUnavailable) {
        this._store.commit('set_maintenance_mode', true)
      }
      throw new Error('Invalid status code received when trying to get customer profiles: ' + response.statusCode)
    }
    const results: CustomerProfile[] = response.content
    const json = window.localStorage.getItem(this._storageKey)
    if (!json) {
      return results.map((cp) => {
        return {
          ...cp,
          installationEnvironments: cp.installationEnvironments.map((it) => {
            return {
              ...it,
              host: `https://${it.host}/`,
            }
          }),
        }
      })
    }
    const data: StorableInstallTarget[] = JSON.parse(json) || []
    const parsedTargets: {
      [id: string]: InstallTarget[]
    } = {}
    for (const target of data) {
      if (!target.customerProfileIds) continue
      if (target.accessToken) {
        const parts = target.accessToken.split('.')
        const body = JSON.parse(atob(parts[1]))
        const expirationDate = new Date(body.exp * 1000)
        if (expirationDate > new Date()) {
          target.decodedTokenBody = body
        } else {
          target.accessToken = null
          target.decodedTokenBody = null
        }
      }
      for (const customerProfileId of target.customerProfileIds) {
        const profile = results.filter((r) => r.id === customerProfileId)[0]
        if (!profile) continue
        const match = profile.installationEnvironments.filter((ie) => `https://${ie.host}/` === target.host)[0]
        if (!match) continue
        const parsedTarget: InstallTarget = {
          customerProfileId: profile.id,
          host: target.host,
          userEnvironmentSettings: match.userEnvironmentSettings,
          accessToken: target.accessToken,
          decodedTokenBody: target.decodedTokenBody,
        }
        if (!parsedTargets[profile.id]) parsedTargets[profile.id] = [parsedTarget]
        else parsedTargets[profile.id].push(parsedTarget)
      }
    }
    const customerProfiles: CustomerProfile[] = []
    for (const profile of results) {
      for (const target of profile.installationEnvironments) {
        const url = `https://${target.host}/`
        const match = parsedTargets[profile.id] && parsedTargets[profile.id].filter((p) => p.host === url)[0]
        if (!match) {
          const parsedTarget: InstallTarget = {
            customerProfileId: profile.id,
            host: url,
            userEnvironmentSettings: target.userEnvironmentSettings,
          }
          if (!parsedTargets[profile.id]) parsedTargets[profile.id] = [parsedTarget]
          else parsedTargets[profile.id].push(parsedTarget)
        }
      }
      customerProfiles.push({
        ...profile,
        installationEnvironments: parsedTargets[profile.id] || [],
      })
    }
    this.setInstallTargetsByProfiles(customerProfiles)
    return customerProfiles
  }

  private setInstallTargetsByProfiles(profiles: CustomerProfile[]): void {
    const targets: InstallTarget[] = []
    for (const profile of profiles) {
      targets.push(...profile.installationEnvironments)
    }
    this.setInstallTargets(targets)
  }

  public setInstallTargets(targets: InstallTarget[]): void {
    const storable: StorableInstallTarget[] = []
    for (const target of targets) {
      const match = storable.filter((s) => s.host === target.host)[0]
      if (match) {
        match.customerProfileIds.push(target.customerProfileId)
      } else {
        storable.push({
          customerProfileIds: [target.customerProfileId],
          host: target.host,
          accessToken: target.accessToken,
        })
      }
    }
    window.localStorage.setItem(this._storageKey, JSON.stringify(storable))
  }

  private addInstallTarget(target: Partial<InstallTarget>): void {
    const json = window.localStorage.getItem(this._storageKey)
    const data: StorableInstallTarget[] = JSON.parse(json) || []
    const match = data.filter((t) => t.host === target.host)[0]
    if (match) {
      if (!match.customerProfileIds.filter((cp) => cp === target.customerProfileId).length) {
        match.customerProfileIds.push(target.customerProfileId)
      }
      match.accessToken = target.accessToken
    } else {
      data.push({
        customerProfileIds: [target.customerProfileId],
        host: target.host,
        accessToken: target.accessToken,
      })
    }
    window.localStorage.setItem(this._storageKey, JSON.stringify(data))
  }

  public removeInstallTarget(target: InstallTarget): void {
    const json = window.localStorage.getItem(this._storageKey)
    let data: StorableInstallTarget[] = JSON.parse(json) || []
    const match = data.filter((t) => t.host === target.host)[0]
    if (match) {
      match.customerProfileIds = match.customerProfileIds.filter((cp) => cp !== target.customerProfileId)
      if (!match.customerProfileIds.length) {
        data = data.filter((d) => d.host !== target.host)
      }
    }
    window.localStorage.setItem(this._storageKey, JSON.stringify(data))
  }

  public async deleteInstallTarget(target: InstallTarget): Promise<void> {
    this.removeInstallTarget(target)
    const response = await this._client.deleteAsync(
      `/api/gallery/customerProfiles/${target.customerProfileId}/environments`,
      JSON.stringify({
        Host: target.host,
      }),
      {
        Authorization: 'Bearer ' + this._sessionService.bearerToken,
        'Content-Type': ContentType.ApplicationJson,
      },
    )
    if (!response.isSuccessStatusCode) {
      if (response.statusCode === HttpStatusCode.ServiceUnavailable) {
        this._store.commit('set_maintenance_mode', true)
      }
      if (response.statusCode === HttpStatusCode.NotFound) return
      throw new Error(
        'Invalid status code received when trying to delete installation environment: ' + response.statusCode,
      )
    }
  }
  public async saveInstallTarget(
    target: Partial<InstallTarget>,
    installedPackages: InstalledPackage[] = [],
    throwOnError: boolean = true,
  ): Promise<void> {
    this.addInstallTarget(target)
    try {
      let productVersion: string = null
      if (target.accessToken) {
        const config = await this._dwpConfigService.getConfiguration(target)
        productVersion = config.productVersion
      }
      const response = await this._client.postAsync(
        `/api/gallery/customerProfiles/${target.customerProfileId}/environments`,
        JSON.stringify({
          EnvironmentHost: target.host,
          InstalledPackages: target.accessToken ? installedPackages : null,
          UserEnvironmentSettings: target.userEnvironmentSettings && {
            AllowPreviewPackages: target.userEnvironmentSettings.allowPreviewPackages,
          },
          ProductVersion: productVersion,
        }),
        {
          Authorization: 'Bearer ' + this._sessionService.bearerToken,
          'Content-Type': ContentType.ApplicationJson,
        },
      )
      if (!response.isSuccessStatusCode) {
        if (throwOnError && response.statusCode === HttpStatusCode.ServiceUnavailable) {
          this._store.commit('set_maintenance_mode', true)
        }
        throw new Error('Invalid status code returned when trying to save installation target: ' + response.statusCode)
      }
    } catch (e) {
      console.log(e)
      if (throwOnError) throw e
    }
  }
}
