import '@matrix42/ignition-core/dist/matrix42.ignition-design-system.full-bundle.min.css'
import '@matrix42/extensions-common-components/dist/matrix42.extensions-common-components.css'
import 'reflect-metadata'

import './polyfills'
import Vue from 'vue'
import App from './app.vue'
import router from './router'
import store from './store'
import TypeScriptDependencyInjection from '@dvolper/tsdi-vue'
import IgnitionDesignSystem, { ToasterPlugin } from '@matrix42/ignition-vue'
import { Store } from 'vuex'
import { AcsSessionService, AcsUserService, HttpClient } from '@matrix42/cloud-common'
import { PackageService } from '@/services/package-service'
import { ConfigService } from '@/services/config-service'
import { ServingBehaviour } from '@dvolper/tsdi'
import './validations'
import SessionService from '@/services/session-service'
import { ReviewService } from '@/services/review-service'
import { CustomerProfileService } from '@/services/customer-profile-service'
import { DwpConfigService } from '@/services/dwp-config-service'
import CustomerService from '@/services/customer-service'

Vue.config.productionTip = false

Vue.use(IgnitionDesignSystem)
Vue.use(ToasterPlugin)
Vue.use(TypeScriptDependencyInjection, {
  builder: (dc) => {
    dc.servingBehaviour = ServingBehaviour.Greedy
    dc.add(HttpClient)
    dc.add(Store, () => store)
    dc.add(AcsSessionService, (provider) => {
      const configService = provider.serve(ConfigService)
      return new AcsSessionService({
        baseUri: configService.loadedConfiguration.acsBaseUrl,
        clientId: configService.loadedConfiguration.acsClientId,
      })
    })
    dc.add(AcsUserService, (provider) => {
      const configService = provider.serve(ConfigService)
      return new AcsUserService(provider.serve(HttpClient), provider.serve(AcsSessionService), {
        baseUri: configService.loadedConfiguration.acsBaseUrl,
      })
    })
    dc.add(PackageService, (provider) => {
      const configService = provider.serve(ConfigService)
      return new PackageService(
        provider.serve(HttpClient),
        {
          baseUri: configService.loadedConfiguration.apiBaseUrl,
        },
        provider.serve(AcsSessionService),
        store,
      )
    })
    dc.add(SessionService, (provider) => {
      const configService = provider.serve(ConfigService)
      return new SessionService(
        provider.serve(HttpClient),
        {
          baseUri: configService.loadedConfiguration.apiBaseUrl,
        },
        provider.serve(AcsSessionService),
        store,
      )
    })
    dc.add(ReviewService, (provider) => {
      const configService = provider.serve(ConfigService)
      return new ReviewService(
        provider.serve(HttpClient),
        {
          baseUri: configService.loadedConfiguration.apiBaseUrl,
        },
        provider.serve(AcsSessionService),
        store,
      )
    })
    dc.add(CustomerProfileService, (provider) => {
      const configService = provider.serve(ConfigService)
      return new CustomerProfileService(
        provider.serve(HttpClient),
        {
          baseUri: configService.loadedConfiguration.apiBaseUrl,
        },
        provider.serve(AcsSessionService),
        store,
        provider.serve(DwpConfigService),
      )
    })
    dc.add(CustomerService, (provider) => {
      const configService = provider.serve(ConfigService)
      return new CustomerService(
        provider.serve(HttpClient),
        {
          baseUri: configService.loadedConfiguration.apiBaseUrl,
        },
        provider.serve(AcsSessionService),
        store,
      )
    })
  },
})

new Vue({
  router,
  store,
  render: (h) => h(App),
}).$mount('#app')
