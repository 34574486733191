import {
  AcsSessionService,
  CloudServiceConfiguration,
  ContentType,
  HttpClient,
  HttpStatusCode,
} from '@matrix42/cloud-common'
import { Singleton } from '@dvolper/tsdi'
import { PackageSearchResult } from '@/models/package-search-result'
import { InstallationPlan, PackageProvisioningDetails } from '@matrix42/extension-installer'
import { Package } from '@matrix42/extension-installer'
import { DependencyTree } from '@/models/dependency-tree'
import { SoftwareRequirement } from '@/models/software-requirement'
import { PackageCategory } from '@/models/package-category'
import { InstallTarget } from '@/models/install-target'
import { PackageAsset } from '@matrix42/extensions-common-components'
import { Store } from 'vuex'
import { AppState } from '@/store/app-state'

@Singleton
export class PackageService {
  public constructor(
    private readonly _client: HttpClient,
    configuration: CloudServiceConfiguration,
    private readonly _sessionService: AcsSessionService,
    private readonly _store: Store<AppState>,
  ) {
    this._client.baseUri = configuration.baseUri
  }

  public async getPackageCategories(): Promise<PackageCategory[]> {
    const response = await this._client.getAsync('/api/gallery/packages/categories', {
      Authorization: 'Bearer ' + this._sessionService.bearerToken,
    })
    if (!response.isSuccessStatusCode) {
      if (response.statusCode === HttpStatusCode.ServiceUnavailable) {
        this._store.commit('set_maintenance_mode', true)
      }
      throw new Error('Invalid status code received when trying to get package categories: ' + response.statusCode)
    }
    return response.content
  }

  public async searchPackages(
    query?: string,
    category?: string,
    ignoreObsolete?: boolean,
    allowPreviewPackages?: boolean,
    skip?: number,
    take?: number,
  ): Promise<PackageSearchResult> {
    let uri = '/api/gallery/packages'
    if (query || category || skip != null || take != null || ignoreObsolete != null || allowPreviewPackages != null) {
      const params = new URLSearchParams()
      if (query) params.append('q', query)
      if (category) params.append('category', category)
      if (ignoreObsolete != null) params.append('ignoreObsolete', ignoreObsolete.toString())
      if (allowPreviewPackages != null) params.append('allowPreviewPackages', allowPreviewPackages.toString())
      if (skip != null) params.append('skip', skip.toString())
      if (take != null) params.append('take', take.toString())
      uri += '?' + params.toString()
    }
    const response = await this._client.getAsync(uri, {
      Authorization: 'Bearer ' + this._sessionService.bearerToken,
    })
    if (!response.isSuccessStatusCode) {
      if (response.statusCode === HttpStatusCode.ServiceUnavailable) {
        this._store.commit('set_maintenance_mode', true)
      }
      throw new Error('Invalid status code received when trying to search packages: ' + response.statusCode)
    }
    return response.content
  }

  public async getPackage(
    packageId: string,
    version?: string,
    ignoreHidden?: boolean,
    ignoreObsolete?: boolean,
    allowPreviewPackages?: boolean,
  ): Promise<Package> {
    let uri = '/api/gallery/packages/' + encodeURIComponent(packageId) + '/versions'
    if (version) uri += '/' + encodeURIComponent(version)
    if (ignoreHidden != null || ignoreObsolete != null || allowPreviewPackages != null) {
      const params = new URLSearchParams()
      if (ignoreHidden != null) params.append('ignoreHidden', ignoreHidden.toString())
      if (ignoreObsolete != null) params.append('ignoreObsolete', ignoreObsolete.toString())
      if (allowPreviewPackages != null) params.append('allowPreviewPackages', allowPreviewPackages.toString())
      uri += '?' + params.toString()
    }
    const response = await this._client.getAsync(uri, {
      Authorization: 'Bearer ' + this._sessionService.bearerToken,
    })
    if (!response.isSuccessStatusCode) {
      if (response.statusCode === HttpStatusCode.ServiceUnavailable) {
        this._store.commit('set_maintenance_mode', true)
      }
      throw new Error('Invalid status code received when trying to get package: ' + response.statusCode)
    }
    return response.content
  }

  public async getPackageInstallationPlan(
    packageId: string,
    version: string,
    target?: InstallTarget,
    allowPreviewPackages?: boolean,
  ): Promise<InstallationPlan> {
    let uri =
      '/api/gallery/packages/' +
      encodeURIComponent(packageId) +
      '/versions/' +
      encodeURIComponent(version) +
      '/installation'
    const params = new URLSearchParams()
    params.append('ignoreProductVersionRequirement', false.toString())
    if (target || allowPreviewPackages != null) {
      if (target) params.append('installationEnvironment', target.host)
      if (allowPreviewPackages != null) params.append('allowPreviewPackages', allowPreviewPackages.toString())
    }
    uri += '?' + params.toString()
    const response = await this._client.getAsync(uri, {
      Authorization: 'Bearer ' + this._sessionService.bearerToken,
    })
    if (!response.isSuccessStatusCode) {
      if (response.statusCode === HttpStatusCode.ServiceUnavailable) {
        this._store.commit('set_maintenance_mode', true)
      }
      throw new Error(
        'Invalid status code received when trying to get package installation plan: ' + response.statusCode,
      )
    }
    return response.content
  }

  public async contactPackageAuthor(
    packageId: string,
    version: string,
    subject: string,
    message: string,
  ): Promise<void> {
    const response = await this._client.postAsync(
      '/api/gallery/packages/' +
        encodeURIComponent(packageId) +
        '/versions/' +
        encodeURIComponent(version) +
        '/contact',
      JSON.stringify({
        Subject: subject,
        Message: message,
      }),
      {
        Authorization: 'Bearer ' + this._sessionService.bearerToken,
        'Content-Type': ContentType.ApplicationJson,
      },
    )
    if (!response.isSuccessStatusCode) {
      if (response.statusCode === HttpStatusCode.ServiceUnavailable) {
        this._store.commit('set_maintenance_mode', true)
      }
      throw new Error('Invalid status code received when trying to contact package author: ' + response.statusCode)
    }
    return response.content
  }

  public async getPackageAssets(packageId: string, version: string): Promise<PackageAsset[]> {
    const response = await this._client.getAsync(
      '/api/gallery/packages/' + packageId + '/versions/' + encodeURIComponent(version) + '/assets',
      {
        Authorization: 'Bearer ' + this._sessionService.bearerToken,
        'Content-Type': ContentType.ApplicationJson,
      },
    )
    if (!response.isSuccessStatusCode) {
      if (response.statusCode === HttpStatusCode.ServiceUnavailable) {
        this._store.commit('set_maintenance_mode', true)
      }
      if (response.statusCode === HttpStatusCode.NotFound) return []
      throw new Error('Invalid status code received when trying to get package assets: ' + response.statusCode)
    }
    return response.content
  }

  public async getPackageSoftwareRequirements(packageId: string, version: string): Promise<SoftwareRequirement[]> {
    const response = await this._client.getAsync(
      '/api/gallery/packages/' +
        encodeURIComponent(packageId) +
        '/versions/' +
        encodeURIComponent(version) +
        '/softwareRequirements',
      {
        Authorization: 'Bearer ' + this._sessionService.bearerToken,
      },
    )
    if (!response.isSuccessStatusCode) {
      if (response.statusCode === HttpStatusCode.ServiceUnavailable) {
        this._store.commit('set_maintenance_mode', true)
      }
      if (response.statusCode === HttpStatusCode.NotFound) return []
      throw new Error(
        'Invalid status code received when trying to get package software requirements: ' + response.statusCode,
      )
    }
    return response.content
  }
}
