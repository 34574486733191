























import Vue from 'vue'
import { Component, Prop } from 'vue-property-decorator'
import { M42Button, M42Modal } from '@matrix42/ignition-vue'
import { Getter } from 'vuex-class'
import { Resolve } from '@dvolper/tsdi'
import { InstallService } from '@/services/install-service'

@Component({
  components: {
    M42Button,
    M42Modal,
  },
})
export default class RefreshSessionModal extends Vue {
  @Resolve
  private readonly _installService: InstallService

  @Getter('refresh_session')
  public targetHost: string

  @Prop({
    type: Boolean,
    default: false,
  })
  public open: boolean

  public onLogin(): void {
    this._installService.authenticateTarget(this.targetHost)
  }
}
