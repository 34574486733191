












































import Vue from 'vue'
import { Component, Prop } from 'vue-property-decorator'
import { M42Button, M42Input, M42Modal, M42Select } from '@matrix42/ignition-vue'
import { InstallTarget } from '@/models/install-target'
import { Getter } from 'vuex-class'
import { DataState } from '@/store/app-state'
import { CustomerProfile } from '@/models/customer-profile'

@Component({
  components: {
    M42Button,
    M42Input,
    M42Modal,
    M42Select,
  },
})
export default class ChooseCustomerProfileModal extends Vue {
  public isLoading: boolean = false
  public targetCustomerProfile: string = null

  @Getter('selected_customer_profile')
  public selectedCustomerProfile: string

  @Getter('customer_profiles')
  public customerProfiles: DataState<CustomerProfile>

  @Prop({
    type: Boolean,
    default: false,
  })
  public open: boolean

  @Prop({
    type: Object,
    default: () => <Partial<InstallTarget>>null,
  })
  public target: Partial<InstallTarget>

  public onAddTarget(): void {
    this.isLoading = true
    this.$emit('add', {
      ...this.target,
      customerProfileId: this.targetCustomerProfile,
    })
  }

  public reset(): void {
    this.isLoading = false
    this.targetCustomerProfile = this.selectedCustomerProfile
  }
}
