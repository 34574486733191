import { Singleton } from '@dvolper/tsdi'
import { AcsSessionService, CloudServiceConfiguration, HttpClient, HttpStatusCode } from '@matrix42/cloud-common'
import { Store } from 'vuex'
import { AppState } from '@/store/app-state'

@Singleton
export default class SessionService {
  public constructor(
    private readonly _client: HttpClient,
    configuration: CloudServiceConfiguration,
    private readonly _session: AcsSessionService,
    private readonly _store: Store<AppState>,
  ) {
    this._client.baseUri = configuration.baseUri
  }

  public async getSessionPermissions(): Promise<string[]> {
    const response = await this._client.getAsync('/api/gallery/session/permissions', {
      Authorization: 'Bearer ' + this._session.bearerToken,
    })
    if (!response.isSuccessStatusCode) {
      if (response.statusCode === HttpStatusCode.ServiceUnavailable) {
        this._store.commit('set_maintenance_mode', true)
      }
      throw new Error('Invalid status code received when trying to get session permissions: ' + response.statusCode)
    }
    return response.content
  }
}
