import { InstallTarget } from '@/models/install-target'

export enum ServiceConnectionState {
  Unknown,
  Pending,
  Healthy,
  Unhealthy,
  Denied,
}

export interface CustomerProfile {
  readonly id: string
  readonly name: string
  readonly vendorId: string
  readonly vendorName: string
  readonly installationEnvironments: InstallTarget[]
  readonly isDefault: boolean
  readonly serviceConnectionState?: ServiceConnectionState
}
