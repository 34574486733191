












































import Vue from 'vue'
import { Component, Prop } from 'vue-property-decorator'
import extensionInstaller, { LogLevel, sortVersions } from '@matrix42/extension-installer'
import { M42Spinner, ToastType } from '@matrix42/ignition-vue'
import { Package } from '@matrix42/extension-installer'
import { Getter } from 'vuex-class'
import { DataState } from '@/store/app-state'
import { InstallTarget } from '@/models/install-target'
import { Resolve } from '@dvolper/tsdi'
import { AcsSessionService } from '@matrix42/cloud-common'
import { ConfigService } from '@/services/config-service'

@Component({
  components: {
    M42Spinner,
  },
})
export default class ExtensionInstaller extends Vue {
  @Resolve
  private readonly _sessionService: AcsSessionService
  @Resolve
  private readonly _configService: ConfigService
  public installationComplete: boolean = false
  public installationFailed: boolean = false
  public installLogs: string = ''

  @Getter('current_target')
  public currentTarget: InstallTarget

  @Getter('selected_target')
  public selectedTargetHost: string

  @Prop({
    type: Object,
    default: null,
  })
  public installPackage: Package

  @Prop({
    type: String,
    default: null,
  })
  public installVersion: string

  public async startInstallation(): Promise<boolean> {
    if (!this.installPackage) return false
    const version = this.installVersion || sortVersions(this.installPackage.versions).reverse()[0]
    if (!version) return false
    this.installationComplete = false
    this.installationFailed = false
    this.installLogs = ''
    try {
      let targetHost = this.currentTarget.host
      if (targetHost.startsWith('https://')) targetHost = targetHost.substring('https://'.length)
      if (targetHost.endsWith('/')) targetHost = targetHost.substring(0, targetHost.length - 1)
      let galleryHost = this._configService.loadedConfiguration.apiBaseUrl
      if (galleryHost.startsWith('https://')) galleryHost = galleryHost.substring('https://'.length)
      const result = await extensionInstaller.start(this.installPackage.packageId, version, {
        targetHost,
        targetAccessToken: this.currentTarget.accessToken,
        galleryAccessToken: this._sessionService.bearerToken,
        galleryHost,
        ignorePurchaseRequirement: true,
        ignoreProductVersionRequirement: false,
        allowPreviewPackages:
          this.currentTarget.userEnvironmentSettings && this.currentTarget.userEnvironmentSettings.allowPreviewPackages,
        logCallback: (level, message) => {
          if (level < LogLevel.Info) return
          if (level > LogLevel.Info) {
            const logCss =
              level === LogLevel.Warning ? 'm42-terminal__highlight--warning' : 'm42-terminal__highlight--error'
            message = `<span class="${logCss}">${message}</span>`
          }
          this.installLogs += message + '\n'
          this.$nextTick(() => {
            const terminal: HTMLDivElement = <any>this.$refs.logTerminal
            if (terminal) {
              terminal.scrollTop = terminal.scrollHeight
            }
          })
        },
      })
      this.$store.commit('require_installations_refresh', true)
      if (result.hasFailed) {
        this.installationFailed = true
        this.$popToast(
          ToastType.Error,
          'There was an error installing the Extension. Please view the installation log for more details.',
        )
        return false
      } else {
        this.installationComplete = true
        return true
      }
    } catch (e) {
      console.log(e)
      this.$popToast(
        ToastType.Error,
        'There was an error installing the Extension. Please try again later or contact our support.',
      )
      return false
    }
  }
}
