import Vue from 'vue'
import VueRouter from 'vue-router'

Vue.use(VueRouter)

// app-gallery
const Gallery = () => import(/* webpackChunkName: "app-gallery" */ '../views/gallery.vue')
const Installer = () => import(/* webpackChunkName: "app-gallery" */ '../views/installer.vue')
const Details = () => import(/* webpackChunkName: "app-gallery" */ '../views/details.vue')
const CustomerProfiles = () => import(/* webpackChunkName: "app-gallery" */ '../views/customer-profiles.vue')

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
})

router.addRoutes([
  {
    path: '',
    component: Gallery,
  },
  {
    path: '/profiles',
    component: CustomerProfiles,
  },
  {
    path: '/:packageId/:version?',
    components: {
      default: Gallery,
      'additional-content': Details,
    },
    props: {
      default: false,
      'additional-content': true,
    },
  },
  {
    path: '/install/:packageId/:version?',
    components: {
      default: Gallery,
      'additional-content': Installer,
    },
    props: {
      default: false,
      'additional-content': true,
    },
  },
])

export default router
