





























































































































































import Vue from 'vue'
import { Component, Watch } from 'vue-property-decorator'
import { AcsSessionService, AcsUserProfile, AcsUserService } from '@matrix42/cloud-common'
import { Getter } from 'vuex-class'
import { ConfigService } from '@/services/config-service'
import { Resolve } from '@dvolper/tsdi'
import { InstallService } from '@/services/install-service'
import {
  M42AppContent,
  M42Banner,
  M42Button,
  M42ContextMenu,
  M42Header,
  M42Icon,
  M42Select,
  M42Spinner,
  M42Toaster,
  ToastType,
  UiAction,
} from '@matrix42/ignition-vue'
import { DataState } from '@/store/app-state'
import { InstallTarget } from '@/models/install-target'
import { ValidationObserver, ValidationProvider } from 'vee-validate'
import RefreshSessionModal from '@/components/modals/refresh-session-modal.vue'
import AddDwpEnvironmentModal from '@/components/modals/add-dwp-environment-modal.vue'
import { InstalledPackage } from '@/models/installed-package'
import { compareVersions, Package, sortVersions } from '@matrix42/extension-installer'
import { PackageService } from '@/services/package-service'
import SessionService from '@/services/session-service'
import { Configuration } from '@/models/configuration'
import InstallGalleryHelperModal from '@/components/modals/install-gallery-helper-modal.vue'
import EnvironmentSettingsModal from '@/components/modals/environment-settings-modal.vue'
import { getHashParameter, setHashParameter } from '@/common/hash-utils'
import { CustomerProfileService } from '@/services/customer-profile-service'
import { CustomerProfile, ServiceConnectionState } from '@/models/customer-profile'
import ChooseCustomerProfileModal from '@/components/modals/choose-customer-profile-modal.vue'

@Component({
  components: {
    ChooseCustomerProfileModal,
    EnvironmentSettingsModal,
    AddDwpEnvironmentModal,
    InstallGalleryHelperModal,
    RefreshSessionModal,
    M42AppContent,
    M42Banner,
    M42Button,
    M42ContextMenu,
    M42Header,
    M42Icon,
    M42Spinner,
    M42Select,
    M42Toaster,
    ValidationObserver,
    ValidationProvider,
  },
})
export default class App extends Vue {
  @Resolve
  private readonly _configService: ConfigService
  @Resolve
  private readonly _acsSessionService: AcsSessionService
  @Resolve
  private readonly _userService: AcsUserService
  @Resolve
  private readonly _installService: InstallService
  @Resolve
  private readonly _packageService: PackageService
  @Resolve
  private readonly _sessionService: SessionService
  @Resolve
  private readonly _customerProfileService: CustomerProfileService
  public isDev: boolean = !!process.env.VUE_APP_DEV
  public galleryTargetSelectOpen: boolean = false
  public addTargetModalOpen: boolean = false
  public refreshSessionModalOpen: boolean = false
  public environmentSettingsModalOpen: boolean = false
  public isChooseCustomerProfileModalOpen: boolean = false
  public redirectTarget: Partial<InstallTarget> = null
  public headerActions: UiAction[] = [
    {
      id: 'm42-devops-portal',
      icon: 'extensions',
      label: 'Matrix42 DevOps Portal',
    },
    {
      id: 'logout',
      icon: 'logout',
      label: 'Logout',
    },
  ]
  public targetSelectActions: UiAction[] = [
    {
      id: 'add-target',
      label: 'Add new DWP environment',
      icon: 'add',
    },
    {
      id: 'customer-profiles',
      icon: 'users',
      label: 'Manage Customer Profiles',
    },
  ]
  public isInstallGalleryHelperModalOpen: boolean = false
  public environmentSettingsTarget: string = null
  public isAppLoading: boolean = true

  @Getter('identity')
  public userProfile: AcsUserProfile

  @Getter('installed_packages')
  public installedPackages: DataState<InstalledPackage>

  @Getter('installed_packages_mapped')
  public installedPackagesMapped: DataState<Package>

  @Getter('customer_profiles')
  public customerProfiles: DataState<CustomerProfile>

  @Getter('has_error')
  public hasStateError: boolean

  @Getter('is_maintenance_mode')
  public isMaintenanceMode: boolean

  @Getter('selected_target')
  public selectedTarget: string

  @Getter('selected_customer_profile')
  public selectedCustomerProfile: string

  @Getter('current_profile')
  public currentProfile: CustomerProfile

  @Getter('current_target')
  public currentTarget: InstallTarget

  @Getter('refresh_session')
  public refreshSession: string

  @Getter('require_environment')
  public requireEnvironment: boolean

  @Getter('require_environment_settings')
  public requireEnvironmentSettings: boolean

  @Getter('refresh_installations')
  public refreshInstallations: boolean

  @Getter('install_gallery_helper')
  public installGalleryHelper: boolean

  @Getter('subtitles')
  public subtitles: UiAction[]

  @Watch('installGalleryHelper')
  public onInstallGalleryHelperChange(): void {
    if (this.installGalleryHelper) {
      this.isInstallGalleryHelperModalOpen = true
    }
  }

  @Watch('refreshSession')
  public onRefreshSessionChange(): void {
    if (this.refreshSession) {
      this.refreshSessionModalOpen = true
    }
  }

  @Watch('requireEnvironment')
  public onRequireEnvironmentChange(): void {
    if (this.requireEnvironment) {
      this.addTargetModalOpen = true
    }
  }

  @Watch('requireEnvironmentSettings')
  public onRequireEnvironmentSettingsChange(): void {
    if (this.requireEnvironmentSettings) {
      this.environmentSettingsModalOpen = true
      this.$store.commit('require_environment_settings', false)
    }
  }

  @Watch('refreshInstallations')
  public async onRefreshInstallationsChange(): Promise<void> {
    if (this.isAppLoading) return
    if (this.refreshInstallations) {
      if (this.currentTarget && this.currentTarget.accessToken) {
        await this.loadInstalledPackages(this.currentTarget)
      }
      this.$store.commit('require_installations_refresh', false)
    }
  }

  @Watch('selectedTarget')
  public async onSelectedTargetChange(): Promise<void> {
    if (this.isAppLoading) return
    this.$store.commit('clear_data', 'installed_packages')
    this.$store.commit('clear_data', 'installed_packages_mapped')
    this.$store.commit('set_available_updates_count', 0)
    if (this.currentTarget && this.currentTarget.accessToken) {
      await this.loadInstalledPackages(this.currentTarget)
    }
    this.$store.commit('set_invalidate_search_categories', true)
  }

  public get isAppReady(): boolean {
    return !this.isAppLoading && !this.hasError
  }

  public get isTargetLoading(): boolean {
    if (!this.selectedTarget) return false
    return (
      this.installedPackages.isLoading ||
      this.installedPackagesMapped.isLoading ||
      (this.customerProfiles.isLoading && this.isAppLoading)
    )
  }

  public get isManagingProfiles(): boolean {
    return this.$route.path === '/profiles'
  }

  public get hasError(): boolean {
    return this.hasStateError || this.isMaintenanceMode
  }

  public get isLoginRequired(): boolean {
    if (!this.currentTarget) return false
    return !this.currentTarget.accessToken
  }

  public get isInternetExplorer(): boolean {
    const index = window.navigator.userAgent.indexOf('MSIE ')
    return index > 0 || !!window.navigator.userAgent.match(/Trident.*rv:11\./)
  }

  public get galleryTargetSelectOptions(): UiAction[] {
    const items: UiAction[] = []
    if (!this.currentProfile) return items
    for (const target of this.currentProfile.installationEnvironments) {
      items.push({
        id: target.host,
        label: target.host.substring('https://'.length, target.host.length - 1),
      })
    }
    return items
  }

  public async mounted(): Promise<void> {
    this.$store.commit('start_loading', 'packageCategories')
    try {
      const config = await this._configService.loadConfigAsync()
      this.$store.commit('config', config)
      const targetFromRedirect = this._installService.tryGetTargetFromRedirect()
      const hashCategory = getHashParameter('category')
      if (this._acsSessionService.ensureValidSession()) {
        const results = await Promise.all([
          this._userService.getProfileAsync(),
          this._sessionService.getSessionPermissions(),
          this._packageService.getPackageCategories(),
          this.loadTargets(),
          this.loadLatestAvailableExGaHelperVersion(config),
        ])
        this.$store.commit('app_login', results[0])
        this.$store.commit('set_identity_permissions', results[1])
        this.$store.commit('finish_loading', {
          key: 'packageCategories',
          data: results[2],
        })
        window.location.hash = ''
        if (hashCategory) setHashParameter('category', hashCategory)
        if (targetFromRedirect) {
          await this.addTargetFromRedirect(targetFromRedirect)
        }
        if (this.currentTarget && this.currentTarget.accessToken) {
          await this.loadInstalledPackages(this.currentTarget)
        }
        this.checkCookiesAccepted()
        this.isAppLoading = false
      }
    } catch (e) {
      console.log(e)
      this.$store.commit('finish_loading', {
        key: 'packageCategories',
        data: e,
      })
      //TODO use removeHashParameter
      window.location.hash = ''
    }
    window.localStorage.removeItem('M42-TARGET-PROFILE-TMP')
  }

  private async addTargetFromRedirect(targetFromRedirect: Partial<InstallTarget>): Promise<void> {
    let customerProfileId = window.localStorage.getItem('M42-TARGET-PROFILE-TMP')
    window.localStorage.removeItem('M42-TARGET-PROFILE-TMP')
    if (!this.customerProfiles.cache.filter((cp) => cp.id === customerProfileId).length) {
      if (this.customerProfiles.cache.length === 1) {
        customerProfileId = this.selectedCustomerProfile
      } else {
        const match = this.customerProfiles.cache.filter(
          (cp) => cp.installationEnvironments.filter((ie) => ie.host === targetFromRedirect.host).length,
        )[0]
        customerProfileId = (match && match.id) || null
      }
    }
    if (customerProfileId) {
      await this.addTarget({
        ...targetFromRedirect,
        customerProfileId,
      })
    } else {
      this.redirectTarget = targetFromRedirect
      this.$nextTick(() => {
        const modal = <any>this.$refs.chooseCustomerProfileModal
        if (modal) modal.reset()
        this.isChooseCustomerProfileModalOpen = true
      })
    }
  }

  private async addTarget(target: Partial<InstallTarget>): Promise<void> {
    await this._customerProfileService.saveInstallTarget(target)
    await this.loadTargets()
    this.$store.commit('select_customer_profile', target.customerProfileId)
    this.$store.commit('select_target', target.host)
    this.isChooseCustomerProfileModalOpen = false
  }

  public async loadTargets(): Promise<void> {
    this.$store.commit('start_loading', 'customer_profiles')
    try {
      const profiles = await this._customerProfileService.getCustomerProfiles()
      let customerProfileId = window.localStorage.getItem('M42-LAST-SELECTED-PROFILE')
      if (!customerProfileId || !profiles.filter((p) => p.id === customerProfileId).length) {
        customerProfileId = profiles.filter((p) => p.isDefault)[0].id
      }
      let profile = profiles.filter((p) => p.id === customerProfileId)[0]
      if (profile.serviceConnectionState != null && profile.serviceConnectionState !== ServiceConnectionState.Healthy) {
        profile = profiles.filter((p) => p.isDefault)[0]
      }
      this.$store.commit('select_customer_profile', profile.id)
      const target = window.localStorage.getItem('M42-LAST-SELECTED-TARGET')
      if (target && !!profile.installationEnvironments.filter((ie) => ie.host === target).length) {
        this.$store.commit('select_target', target)
      } else if (profile.installationEnvironments.length) {
        this.$store.commit('select_target', profile.installationEnvironments[0].host)
      }
      this.$store.commit('finish_loading', {
        key: 'customer_profiles',
        data: profiles,
      })
    } catch (e) {
      console.log(e)
      this.$store.commit('finish_loading', {
        key: 'customer_profiles',
        data: e,
      })
    }
  }

  private async loadInstalledPackages(target: InstallTarget): Promise<void> {
    this.$store.commit('start_loading', 'installed_packages')
    this.$store.commit('start_loading', 'installed_packages_mapped')
    try {
      const packages = await this._installService.getInstalledPackages(target)
      const mappings: {
        pkg: Package
        installed: InstalledPackage
      }[] = []
      let updateCount = 0
      for (const installed of packages) {
        try {
          const pkg = await this._packageService.getPackage(
            installed.Id,
            null,
            null,
            null,
            target.userEnvironmentSettings.allowPreviewPackages,
          )
          for (const version of pkg.versions) {
            const parts = version.split('-')
            if (parts.length === 1) continue
            if (parts[0] === installed.Version) installed.Version = version
          }
          const latest = sortVersions(pkg.versions)[pkg.versions.length - 1]
          const compare = compareVersions(latest, installed.Version)
          if (compare === 1) updateCount += 1
          mappings.push({
            pkg,
            installed,
          })
        } catch (e) {
          console.log(e)
        }
      }
      this.$store.commit('finish_loading', {
        key: 'installed_packages',
        data: packages,
      })
      this.$store.commit('set_available_updates_count', updateCount)
      const installedPackagesMapped = mappings
        .sort((a, b) => {
          const date1 = new Date(a.installed.LastUpdatedDate)
          const date2 = new Date(b.installed.LastUpdatedDate)
          if (date1 > date2) return -1
          if (date1 < date2) return 1
          return 0
        })
        .map((p) => p.pkg)
      this.$store.commit('finish_loading', {
        key: 'installed_packages_mapped',
        data: installedPackagesMapped,
      })
    } catch (e) {
      console.log(e)
      this.$store.commit('finish_loading', {
        key: 'installed_packages',
        data: e,
      })
      this.$store.commit('finish_loading', {
        key: 'installed_packages_mapped',
        data: e,
      })
      this.$popToast(
        ToastType.Error,
        'There was an error loading the installed Extensions on your DWP environment. Please try again later or contact our support.',
      )
    }
  }

  private async loadLatestAvailableExGaHelperVersion(config: Configuration): Promise<void> {
    try {
      const packages = await this._packageService.getPackage(config.exGaHelperPackageId, null, null, true)
      const latestVersion = sortVersions(packages.versions)[packages.versions.length - 1]
      this.$store.commit('set_latest_helper_version', latestVersion)
    } catch (e) {
      console.log(e)
      this.$popToast(
        ToastType.Error,
        'There was an error loading the latest available Extension Gallery Helper version. Please try again later or contact our support.',
      )
    }
  }

  private checkCookiesAccepted(): void {
    const cookiesAccepted = window.localStorage.getItem('M42-COOKIES-ACCEPTED')
    if (!cookiesAccepted) {
      const toastId = this.$popToast(
        ToastType.Success,
        '<h3 style="padding-top: 0">Cookies</h3>This website uses cookies for anonymous analysis of the usage behavior.<br/>By using this website, you agree to the use of cookies: <a target="_blank" href="https://marketplace.matrix42.com/privacy/">Find out more.</a><br/><button onclick="m42AcceptCookies()" class="m42-button m42-button--primary float-right"><span class="m42-button__text">OKAY</span></button>',
        {
          stay: true,
          icon: 'info',
        },
      )
      ;(<any>window).m42AcceptCookies = () => {
        window.localStorage.setItem('M42-COOKIES-ACCEPTED', new Date().toString())
        this.$dropToast(toastId)
      }
    }
  }

  public onGalleryTargetAction(action: UiAction): void {
    switch (action.id) {
      case 'add-target':
        this.onAddTarget()
        break
      case 'customer-profiles':
        this.manageCustomerProfiles()
        break
      default:
        this.$store.commit('select_target', action.id)
        break
    }
  }

  public manageCustomerProfiles(): void {
    this.$router.push('/profiles')
  }

  public onCloseInstallGalleryHelperModal(): void {
    this.$store.commit('set_install_gallery_helper', false)
    this.isInstallGalleryHelperModalOpen = false
  }

  public onAddTarget(): void {
    this.addTargetModalOpen = true
  }

  public async onRemoveTarget(action: UiAction): Promise<void> {
    this.galleryTargetSelectOpen = false
    if (!this.currentProfile) return
    const targets = this.currentProfile.installationEnvironments.slice()
    for (let index = 0; index < targets.length; index++) {
      const target = targets[index]
      if (target.host === action.id) {
        await this._customerProfileService.deleteInstallTarget(target)
      }
    }
    this.$store.commit('select_target', null)
    await this.loadTargets()
  }

  public onHeaderActionClick(action: UiAction): void {
    switch (action.id) {
      case 'm42-devops-portal':
        window.location.href = this._configService.loadedConfiguration.appBaseUrl + '/portal'
        break
      case 'logout':
        this._acsSessionService.signOut()
        break
    }
  }

  public onGalleryTargetSelectMouseDown(): void {
    this.galleryTargetSelectOpen = true
  }

  public onRefresh(): void {
    window.location.reload()
  }

  public refreshLogin(): void {
    this._installService.authenticateTarget(this.selectedTarget)
  }

  public onUserAvatarClick(): void {
    this._userService.openAcsUserProfile()
  }

  public onRefreshSessionModalClose(): void {
    this.$store.commit('require_session_refresh', null)
    this.refreshSessionModalOpen = false
  }

  public onCloseAddTargetModal(): void {
    this.$store.commit('require_environment', false)
    this.addTargetModalOpen = false
  }

  public onScroll(event: UIEvent): void {
    const content = <HTMLDivElement>event.target
    this.$store.commit('set_content_scroll', content.scrollTop)
  }

  public onEditEnvironmentSettings(item: UiAction): void {
    this.environmentSettingsTarget = item.id
    this.environmentSettingsModalOpen = true
    this.galleryTargetSelectOpen = false
  }

  public async onEnvironmentSettingsModalClose(): Promise<void> {
    this.environmentSettingsModalOpen = false
    await this.loadTargets()
    this.$store.commit('require_packages_refresh', true)
    if (this.$route.path !== '/') {
      const packageId = this.$router.currentRoute.params.packageId
      await this.$router.push('/' + packageId)
    }
  }

  public navigateToHome(): void {
    if (this.$route.path === '/') {
      window.location.reload()
    } else {
      this.$router.push('/')
    }
  }

  public onSubtitleClick(event: any): void {
    if (event['id'].startsWith('/') && this.$route.path !== event['id']) {
      this.$router.push(event['id'])
    }
  }
}
