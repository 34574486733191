import { AcsSessionService, HttpClient } from '@matrix42/cloud-common'
import { Singleton } from '@dvolper/tsdi'
import { InstallTarget } from '@/models/install-target'
import { ConfigService } from '@/services/config-service'
import { InstalledPackage } from '@/models/installed-package'
import { Store } from 'vuex'
import { AppState } from '@/store/app-state'
import { CustomerProfileService } from '@/services/customer-profile-service'

@Singleton
export class InstallService {
  private readonly _tmpStorageKey: string = 'M42-INSTALL-TARGETS-TMP'

  public constructor(
    private readonly _client: HttpClient,
    private readonly _configService: ConfigService,
    private readonly _sessionService: AcsSessionService,
    private readonly _store: Store<AppState>,
    private readonly _customerProfileService: CustomerProfileService,
  ) {}

  public authenticateTarget(url: string): void {
    if (url[url.length - 1] !== '/') {
      url += '/'
    }
    window.localStorage.setItem(this._tmpStorageKey, url)
    const clientId = 'Matrix42.Extensions.Gallery'
    const scope = 'urn:matrix42NewUX'
    const redirectUri = this._configService.loadedConfiguration.appBaseUrl + window.location.pathname
    url += `M42Services/api/sts/authorize?client_id=${clientId}&scope=${scope}&response_type=token&redirect_uri=${encodeURIComponent(
      redirectUri,
    )}&autoLogin=true`
    window.location.href = url
  }

  public tryGetTargetFromRedirect(): Partial<InstallTarget> {
    let url = window.localStorage.getItem(this._tmpStorageKey),
      token: string = null
    const parts = window.location.hash.substr(1).split('&')
    for (const part of parts) {
      const kvp = part.split('=')
      if (kvp[0] === 'access_token') {
        kvp.splice(0, 1)
        token = kvp.join('=')
      } else if (kvp[0] === 'target') {
        kvp.splice(0, 1)
        url = decodeURIComponent(kvp.join('=')) + '/'
      }
    }
    if (!url) return null
    window.location.hash = ''
    window.localStorage.removeItem(this._tmpStorageKey)
    return {
      accessToken: token,
      host: url,
    }
  }

  public async getInstalledPackages(target: InstallTarget): Promise<InstalledPackage[]> {
    const response = await this._client.getAsync(target.host + 'wminstaller/api/package', {
      Authorization: 'Bearer ' + target.accessToken,
    })
    if (!response.isSuccessStatusCode) {
      throw new Error('Invalid status code received when trying to get installed packages: ' + response.statusCode)
    }
    const result = response.content
    await this._customerProfileService.saveInstallTarget(target, result, false)
    return result
  }
}
