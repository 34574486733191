import { Singleton } from '@dvolper/tsdi'
import { AcsSessionService, CloudServiceConfiguration, HttpClient, HttpStatusCode } from '@matrix42/cloud-common'
import { Store } from 'vuex'
import { AppState } from '@/store/app-state'
import { Customer } from '@/models/customer'

@Singleton
export default class CustomerService {
  public constructor(
    private readonly _client: HttpClient,
    configuration: CloudServiceConfiguration,
    private readonly _session: AcsSessionService,
    private readonly _store: Store<AppState>,
  ) {
    this._client.baseUri = configuration.baseUri
  }

  public async getCustomers(customerNumber: string): Promise<Customer[]> {
    const response = await this._client.getAsync('/api/gallery/customers/' + customerNumber, {
      Authorization: 'Bearer ' + this._session.bearerToken,
    })
    if (!response.isSuccessStatusCode) {
      if (response.statusCode === HttpStatusCode.NotFound) return []
      if (response.statusCode === HttpStatusCode.ServiceUnavailable) {
        this._store.commit('set_maintenance_mode', true)
      }
      throw new Error('Invalid status code received when trying to get customer information: ' + response.statusCode)
    }
    return response.content
  }
}
