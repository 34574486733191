
























import Vue from 'vue'
import { Component, Prop, Watch } from 'vue-property-decorator'
import { M42Banner, M42Input, M42Modal } from '@matrix42/ignition-vue'
import { Getter } from 'vuex-class'
import { InstallTarget } from '@/models/install-target'
import { ValidationObserver, ValidationProvider } from 'vee-validate'
import { DataState } from '@/store/app-state'
import { debounce } from '@/utils/debounce'
import { Resolve } from '@dvolper/tsdi'
import { CustomerProfileService } from '@/services/customer-profile-service'
import { CustomerProfile } from '@/models/customer-profile'

@Component({
  components: {
    M42Modal,
    M42Input,
    M42Banner,
    ValidationObserver,
    ValidationProvider,
  },
})
export default class EnvironmentSettingsModal extends Vue {
  @Resolve
  private readonly _customerProfileService: CustomerProfileService
  public onlyStableExtensions: boolean = false
  public debouncedSave: () => void = null
  public installTarget: InstallTarget = null
  public detectChanges: boolean = false

  @Prop({
    type: Boolean,
    default: false,
  })
  public open: boolean

  @Prop({
    type: String,
    default: null,
  })
  public target: string

  @Prop({
    type: String,
    default: null,
  })
  public customerProfile: string

  @Getter('customer_profiles')
  public customerProfiles: DataState<CustomerProfile>

  @Watch('target')
  public onTargetChange(): void {
    this.changeTarget()
  }

  @Watch('customerProfile')
  public onCustomerProfileChange(): void {
    this.changeTarget()
  }

  @Watch('onlyStableExtensions')
  public onlyStableExtensionsChanged(): void {
    this.debouncedSave()
  }

  private changeTarget(): void {
    this.detectChanges = false
    const oldValue = this.onlyStableExtensions
    const profile =
      this.customerProfiles && this.customerProfiles.cache.filter((cp) => cp.id === this.customerProfile)[0]
    const match = profile && profile.installationEnvironments.filter((i) => i.host === this.target)[0]
    if (match) {
      this.onlyStableExtensions = !match.userEnvironmentSettings.allowPreviewPackages
    } else {
      this.onlyStableExtensions = true
    }
    this.installTarget = match ? match : null
    if (oldValue === this.onlyStableExtensions) this.detectChanges = true
  }

  public mounted(): void {
    this.debouncedSave = debounce(async () => {
      if (!this.detectChanges) {
        this.detectChanges = true
        return
      }
      await this._customerProfileService.saveInstallTarget({
        ...this.installTarget,
        userEnvironmentSettings: {
          allowPreviewPackages: !this.onlyStableExtensions,
        },
      })
    }, 250)
  }

  public onChange(): void {
    this.debouncedSave()
  }
}
