import { HttpClient } from '@matrix42/cloud-common'
import { Configuration } from '@/models/configuration'
import { Singleton } from '@dvolper/tsdi'

@Singleton
export class ConfigService {
  private _config?: Configuration

  public get loadedConfiguration(): Configuration {
    return this._config
  }

  public constructor(private readonly _client: HttpClient) {}

  public async loadConfigAsync(): Promise<Configuration> {
    const response = await this._client.getAsync(
      `${process.env.VUE_APP_API_CONFIG_PREFIX || ''}/api/gallery/configuration`,
    )
    if (!response.isSuccessStatusCode) {
      throw new Error('Invalid status code received when trying to load configuration: ' + response.statusCode)
    }
    return (this._config = response.content)
  }
}
