



































import Vue from 'vue'
import { Component, Prop } from 'vue-property-decorator'
import { M42Button, M42Modal, M42ProgressBar, ToastType } from '@matrix42/ignition-vue'
import { Getter } from 'vuex-class'
import { DataState } from '@/store/app-state'
import { PackageService } from '@/services/package-service'
import { Configuration } from '@/models/configuration'
import { InstallTarget } from '@/models/install-target'
import { InstalledPackage } from '@/models/installed-package'
import { Resolve } from '@dvolper/tsdi'
import ExtensionInstaller from '@/components/extension-installer.vue'
import { Package } from '@matrix42/extension-installer'

@Component({
  components: {
    ExtensionInstaller,
    M42Button,
    M42Modal,
    M42ProgressBar,
  },
})
export default class InstallGalleryHelperModal extends Vue {
  @Resolve
  private readonly _packageService: PackageService

  public isHelperInstalled: boolean = false
  public isHelperInstalling: boolean = false
  public packageInformation: Package = null

  @Getter('config')
  public configuration: Configuration

  @Getter('latest_helper_version')
  public latestHelperVersion: string

  @Getter('current_target')
  public currentTarget: InstallTarget

  @Getter('selected_target')
  public selectedTargetHost: string

  @Prop({
    type: Boolean,
    default: false,
  })
  public open: boolean

  public get allowPreviewPackages(): boolean {
    return (
      this.currentTarget &&
      this.currentTarget.userEnvironmentSettings &&
      this.currentTarget.userEnvironmentSettings.allowPreviewPackages
    )
  }

  private async installLatestHelperExtension(): Promise<void> {
    try {
      const extensionInstaller = <any>this.$refs.extensionInstaller
      //TODO error handling
      await extensionInstaller.startInstallation()
      this.isHelperInstalling = false
      this.isHelperInstalled = true
      this.$store.commit('require_installations_refresh', true)
    } catch (e) {
      console.log(e)
      this.$popToast(
        ToastType.Error,
        'There was an error installing the latest Extension Gallery Helper. Please try again later or contact our support.',
      )
    }
  }

  private async prepareInstallation(): Promise<void> {
    try {
      this.packageInformation = await this._packageService.getPackage(
        this.configuration.exGaHelperPackageId,
        this.latestHelperVersion,
        false,
      )
    } catch (e) {
      console.log(e)
      this.$popToast(
        ToastType.Error,
        'There was an error loading the latest Extension Gallery Helper. Please try again later or contact our support.',
      )
    }
  }

  public async onConfirmInstallation(): Promise<void> {
    this.isHelperInstalling = true
    await this.prepareInstallation()
    this.$nextTick(async () => {
      await this.installLatestHelperExtension()
    })
  }

  public async onNextStep(): Promise<void> {
    this.$emit('update:open', false)
  }
}
