import { Singleton } from '@dvolper/tsdi'
import { HttpClient } from '@matrix42/cloud-common'
import { InstallTarget } from '@/models/install-target'
import { DwpConfiguration } from '@/models/dwp-configuration'

@Singleton
export class DwpConfigService {
  public constructor(private readonly _client: HttpClient) {}

  public async getConfiguration(target: Partial<InstallTarget>): Promise<DwpConfiguration> {
    const apiResponse = await this._client.getAsync(target.host + 'm42Services/api/wmConfig')
    if (apiResponse.isSuccessStatusCode) return apiResponse.content
    const fileResponse = await this._client.getAsync(target.host + 'wm/config.json')
    if (!fileResponse.isSuccessStatusCode) {
      throw new Error('Invalid status code received when trying to load DWP config.json: ' + fileResponse.statusCode)
    }
    return fileResponse.content
  }
}
