import { extend, localize } from 'vee-validate'
import { max, min, regex, required } from 'vee-validate/dist/rules'

extend('regex', regex)
extend('required', required)
extend('max', max)
extend('min', min)

localize({
  en: {
    messages: {
      required: 'required',
      min: 'min',
      max: 'max',
      email: 'email',
    },
  },
})
