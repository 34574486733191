import Vue from 'vue'
import Vuex from 'vuex'
import { compareVersions } from '@matrix42/extension-installer'
import { AppState } from './app-state'
import { AcsSessionService } from '@matrix42/cloud-common'
import { DependencyContainer } from '@dvolper/tsdi'

Vue.use(Vuex)

export default new Vuex.Store<AppState>({
  state: {
    identity: {
      profile: null,
      permissions: [],
    },
    config: null,
    data: {
      packages: {
        cache: [],
        error: '',
        isLoading: false,
      },
      customer_profiles: {
        cache: [],
        error: '',
        isLoading: false,
      },
      installed_packages: {
        cache: [],
        error: '',
        isLoading: false,
      },
      installed_packages_mapped: {
        cache: [],
        error: '',
        isLoading: false,
      },
      packageCategories: {
        cache: [],
        error: '',
        isLoading: false,
      },
      selectedTarget: null,
      selectedCustomerProfile: null,
      refreshSession: null,
      requireEnvironment: false,
      requireEnvironmentSettings: false,
      refreshInstallations: false,
      refreshPackages: false,
      availableUpdatesCount: 0,
      latestHelperVersion: null,
      installGalleryHelper: false,
      subtitles: [],
    },
    ui: {
      contentScroll: 0,
      invalidateSearchCategories: false,
    },
    isMaintenanceMode: false,
  },
  mutations: {
    app_login: (state, payload) => {
      Vue.set(state.identity, 'profile', {
        ...state.identity.profile,
        ...payload,
      })
    },
    set_identity_permissions: (state, payload) => {
      Vue.set(state.identity, 'permissions', payload)
    },
    app_logout: (state) => {
      state.identity = {
        profile: null,
        permissions: [],
      }
      const sessionService = DependencyContainer.global.serve(AcsSessionService)
      sessionService.clearSession()
      window.location.href = state.config.appBaseUrl
    },
    config: (state, payload) => {
      Vue.set(state, 'config', payload)
    },
    finish_loading: (state, payload) => {
      if (!(<any>state.data)[payload.key]) return
      if (payload.data instanceof Error) {
        Vue.set(
          (<any>state.data)[payload.key],
          'error',
          `There was an error. Please try again.<br/>(${payload.data.message})`,
        )
      } else {
        Vue.set((<any>state.data)[payload.key], 'cache', payload.data)
      }
      Vue.set((<any>state.data)[payload.key], 'isLoading', false)
    },
    start_loading: (state, payload) => {
      Vue.set((<any>state.data)[payload], 'isLoading', true)
      Vue.set((<any>state.data)[payload], 'error', '')
    },
    select_target: (state, payload) => {
      state.data.selectedTarget = payload
      if (payload) window.localStorage.setItem('M42-LAST-SELECTED-TARGET', payload)
      else window.localStorage.removeItem('M42-LAST-SELECTED-TARGET')
    },
    select_customer_profile: (state, payload) => {
      state.data.selectedCustomerProfile = payload
      if (payload) window.localStorage.setItem('M42-LAST-SELECTED-PROFILE', payload)
      else window.localStorage.removeItem('M42-LAST-SELECTED-PROFILE')
    },
    require_session_refresh: (state, payload) => {
      state.data.refreshSession = payload
    },
    require_environment: (state, payload) => {
      state.data.requireEnvironment = payload
    },
    require_environment_settings: (state, payload) => {
      state.data.requireEnvironmentSettings = payload
    },
    require_installations_refresh: (state, payload) => {
      state.data.refreshInstallations = payload
    },
    require_packages_refresh: (state, payload) => {
      state.data.refreshPackages = payload
    },
    clear_data: (state, payload) => {
      if (!(<any>state.data)[payload]) return
      Vue.set((<any>state.data)[payload], 'cache', [])
    },
    set_available_updates_count: (state, payload) => {
      state.data.availableUpdatesCount = payload
    },
    set_latest_helper_version: (state, payload) => {
      state.data.latestHelperVersion = payload
    },
    set_install_gallery_helper: (state, payload) => {
      state.data.installGalleryHelper = payload
    },
    set_content_scroll: (state, payload) => {
      state.ui.contentScroll = payload
    },
    set_maintenance_mode: (state, payload) => {
      state.isMaintenanceMode = payload
    },
    set_invalidate_search_categories: (state, payload) => {
      state.ui.invalidateSearchCategories = payload
    },
    set_subtitles: (state, payload) => {
      state.data.subtitles = payload
    },
  },
  actions: {},
  modules: {},
  getters: {
    identity: (state) => state.identity.profile,
    identity_permissions: (state) => state.identity.permissions,
    config: (state) => state.config,
    packages: (state) => state.data.packages,
    installed_packages: (state) => state.data.installed_packages,
    installed_packages_mapped: (state) => state.data.installed_packages_mapped,
    customer_profiles: (state) => state.data.customer_profiles,
    selected_target: (state) => state.data.selectedTarget,
    selected_customer_profile: (state) => state.data.selectedCustomerProfile,
    has_error: (state) =>
      !!state.data.customer_profiles.error || !!state.data.packages.error || !!state.data.packageCategories.error,
    is_maintenance_mode: (state) => !!state.isMaintenanceMode,
    refresh_session: (state) => state.data.refreshSession,
    refresh_installations: (state) => state.data.refreshInstallations,
    refresh_packages: (state) => state.data.refreshPackages,
    require_environment: (state) => state.data.requireEnvironment,
    require_environment_settings: (state) => state.data.requireEnvironmentSettings,
    available_updates_count: (state) => state.data.availableUpdatesCount,
    latest_helper_version: (state) => state.data.latestHelperVersion,
    install_gallery_helper: (state) => state.data.installGalleryHelper,
    content_scroll: (state) => state.ui.contentScroll,
    package_categories: (state) => state.data.packageCategories,
    invalidate_search_categories: (state) => state.ui.invalidateSearchCategories,
    subtitles: (state) => state.data.subtitles,
    current_profile: (state) => {
      if (!state.data.selectedCustomerProfile) return null
      return state.data.customer_profiles.cache.filter((p) => p.id === state.data.selectedCustomerProfile)[0]
    },
    current_target: (state) => {
      if (!state.data.selectedCustomerProfile || !state.data.selectedTarget) return null
      const profile = state.data.customer_profiles.cache.filter((p) => p.id === state.data.selectedCustomerProfile)[0]
      if (!profile) return null
      return profile.installationEnvironments.filter((ie) => ie.host === state.data.selectedTarget)[0] || null
    },
    is_latest_helper_installed: (state) => {
      const helperPackage = state.data.installed_packages.cache.filter(
        (i) => i.Id === state.config.exGaHelperPackageId,
      )[0]
      if (!helperPackage) return false
      const compare = compareVersions(state.data.latestHelperVersion, helperPackage.Version, true)
      if (compare === -1) return true
      return compare !== 1
    },
  },
})
